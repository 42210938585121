import React, { Fragment } from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const Lock: React.FC<IconBasePropsWithColor> = ({
  width = 12,
  height = 15,
  color = colors.black,
  className = '',
  strokeWidth = 1,
}) => {
  return (
    <Fragment>
      <style jsx>{`
        .paylink-lock-icon {
          position: relative;
          top: 2px;
        }
      `}</style>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 12 15"
        className={className}
      >
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
          <rect
            width="10.165"
            height="9"
            y="4"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
            rx="2"
          />
          <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
            d="M8.132 4V2.963C8.132 1.327 6.767 0 5.082 0 3.399 0 2.034 1.327 2.034 2.963V4"
          />
          <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
            d="M5.083 8v2"
          />
          <ellipse cx="5.083" cy="8" fill={color} fillRule="nonzero" rx="1.017" ry="1" />
        </g>
      </svg>
    </Fragment>
  );
};

export default Lock;
